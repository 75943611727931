<template>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-xl-5 col-lg-5 col-md-8">
          <div class="card">
            <div class="card-body">
              <div class="auth-logo">
                <!-- <h2 class="text-primary">
                  <strong>Werkstattmanager</strong>
                </h2> -->
                <img
                  src="@/assets/images/logo.png"
                  class="img-fluid rounded-normal"
                  alt="logo"
                />
              </div>
              <h2 class="mb-2 text-center">
                {{ $t("recoverPassMsgs.resetPassHeading") }}
              </h2>
              <p class="text-center">{{ $t("recoverPassMsgs.topText") }}</p>

              <div class="mt-3 row align-items-center justify-content-center">
                <div class="form-group col-xl-4 col-lg-6 col-md-4 col-sm-4">
                  <select
                    class="form-control"
                    v-model="selectedLang"
                    @change="changeLang"
                  >
                    <option :value="LANG_ABBREVIATIONS.EN">
                      {{ $t("langNames.english") }}
                    </option>
                    <option :value="LANG_ABBREVIATIONS.DE">
                      {{ $t("langNames.german") }}
                    </option>
                  </select>
                </div>
              </div>

              <form @submit.prevent="onSubmit">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>{{ $t("newPassword") }}</label>
                      <input
                        class="form-control"
                        type="password"
                        :placeholder="$t('password')"
                        v-model="password"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>{{ $t("confirmNewPassword") }}</label>
                      <input
                        class="form-control"
                        type="password"
                        :placeholder="$t('userMsgs.repeatPassword')"
                        v-model="confirmPassword"
                        required
                      />
                    </div>
                  </div>
                </div>

                <div
                  v-if="error === PASSWORDS_NOT_MATCH"
                  class="alert alert-danger mt-4"
                  role="alert"
                >
                  {{ $t("recoverPassMsgs.passwordsNotMatch") }}
                </div>

                <div
                  v-if="error === PASSWORDS_MIN_LENGTH"
                  class="alert alert-danger mt-4"
                  role="alert"
                >
                  {{ $t("recoverPassMsgs.passwordsMinLength") }}
                </div>

                <div
                  v-if="error === PASSWORDS_ALL_NUMERIC"
                  class="alert alert-danger mt-4"
                  role="alert"
                >
                  {{ $t("recoverPassMsgs.passwordsAllNumeric") }}
                </div>

                <div class="text-center mt-3">
                  <b-button
                    type="submit"
                    tag="button"
                    class="btn-rounded btn-block"
                    variant="primary"
                    :disabled="loading"
                  >
                    <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                    <span v-else>{{ $t("recoverPassMsgs.resetBtn") }}</span>
                  </b-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  LANG_ABBREVIATIONS,
  PASSWORD_VALIDATIONS,
  PASSWORD_ALL_NUMERIC_REGEX,
  TOAST_OPTIONS,
} from "../../common/constants";

export default {
  name: "ResetPassword",

  data() {
    return {
      LANG_ABBREVIATIONS,

      password: "",
      confirmPassword: "",
      loading: false,
      error: null,

      selectedLang: LANG_ABBREVIATIONS.EN,
      ...PASSWORD_VALIDATIONS,
    };
  },

  methods: {
    ...mapActions([
      "resetPasswordConfirm",
      "changeCurrLang",
      "updateResetPasswordTokenInvalid",
    ]),

    async onSubmit() {
      this.loading = true;
      this.error = null;
      if (this.password !== this.confirmPassword) {
        this.error = PASSWORD_VALIDATIONS.PASSWORDS_NOT_MATCH;
      } else {
        if (this.password.length < 3) {
          this.error = PASSWORD_VALIDATIONS.PASSWORDS_MIN_LENGTH;
        } else if (PASSWORD_ALL_NUMERIC_REGEX.test(this.password)) {
          this.error = PASSWORD_VALIDATIONS.PASSWORDS_ALL_NUMERIC;
        } else {
          const token = encodeURIComponent(this.$route.query.token);
          try {
            await this.resetPasswordConfirm({
              password: this.password,
              token: token,
            });

            this.updateResetPasswordTokenInvalid(
              TOAST_OPTIONS.RESET_SUCCESSFUL
            );
            this.$router.push({ name: "login" });
          } catch (error) {
            this.makeToast("danger", this.$t("errorMsgs.genErrorMsg"));
          }
        }
      }
      this.loading = false;
    },

    makeToast(variant = null, msg, title) {
      this.$bvToast.toast(msg, {
        title,
        variant: variant,
        solid: true,
        autoHideDelay: 2500,
      });
    },

    changeLang() {
      this.$i18n.locale = this.selectedLang;
      this.changeCurrLang(this.selectedLang);
    },
  },

  computed: {
    ...mapGetters(["getCurrLanguage"]),
  },

  mounted() {
    this.selectedLang = this.getCurrLanguage;
  },
};
</script>
